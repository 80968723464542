<!-- 乡贤管理 -->
<template>
  <div>
    <!-- 通用标题 -->
    <!--    <headtitle Otitle="乡贤管理" />-->

    <el-card class="box-card">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><a @click="request">首页</a></el-breadcrumb-item>
        <el-breadcrumb-item>乡贤管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="hr-20"></div>
      <div class="searchtool fl">
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-plus"
          @click="addmanage()"
          >新增</el-button
        >
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-refresh-right"
          @click="requestRefresh()"
          >刷新</el-button
        >
        <el-button type="warning" plain size="mini" @click="exportTable"
          >导出全部</el-button
        >
        <el-button
          :type="thistownship ? 'success' : 'warning'"
          size="small"
          icon="el-icon-upload2"
          @click="isUpload"
          >批量导入:{{ thistownship }}</el-button
        >
      </div>
      <div class="searchtool fr">
        <el-form class="ruleForm" size="mini" inline>
          <el-form-item>
            <el-input
              v-model="searchForm.keyword"
              placeholder="请输入关键字"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="searchSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        highlight-current-row
        @selection-change="handleSelectionChange"
        @current-change="handleCurrentChange"
      >
        <!--                <el-table-column type="selection" width="55"></el-table-column>-->
        <el-table-column
          prop="township"
          label="地点名"
          show-overflow-tooltip
        ></el-table-column>

        <el-table-column label="创建时间" width="100">
          <template slot-scope="scope">{{
            scope.row.createTime | formatDate
          }}</template>
        </el-table-column>
        <el-table-column
          prop="worthyCount"
          label="乡贤数量"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <span> {{ scope.row.worthyCount }}人 </span>
          </template>
        </el-table-column>
        <el-table-column label="上次修改时间" width="150" align="center">
          <template slot-scope="scope">{{
            scope.row.modifiedTime | formatDate
          }}</template>
        </el-table-column>
        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="handleEdit(scope.$index, scope.row)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="hr-20"></div>
      <span class="fl"
        >共计 <b style="color: #1d72c1">{{ total }}</b> 个地区</span
      >
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :current-page="currtow"
        :page-size="pageSize"
        @current-change="requestPage"
      />
    </el-card>
    <div v-if="adddialog">
      <el-dialog
        title="新增"
        :visible.sync="adddialog"
        width="30%"
        :before-close="addClose"
        style="min-width: 400px"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-position="left"
          label-width="100px"
        >
          <el-form-item label="乡镇名称" prop="township">
            <el-input v-model="ruleForm.township"></el-input>
          </el-form-item>
          <el-form-item label="管理员名称" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="账号" prop="username">
            <el-input v-model="ruleForm.username"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              v-model="ruleForm.password"
              show-password
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系方式" prop="cellphone">
            <el-input v-model="ruleForm.cellphone"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button class="text-color" @click="addClose()">取 消</el-button>
          <el-button
            type="primary"
            :disabled="disabled"
            @click="addrule('ruleForm')"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
    <el-dialog
      :title="thistownship + ': 乡贤批量导入'"
      :visible.sync="dialogUpload"
      width="30%"
    >
      <el-upload
        class="upload-demo"
        ref="upload"
        :action="host + '/worthy/importExcel'"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :data="UploadData"
        accept=".xlsx"
        :file-list="fileList"
        :auto-upload="false"
      >
        <el-button slot="trigger" size="small" type="primary"
          >选取文件</el-button
        >
        <el-button
          style="margin-left: 10px;"
          size="small"
          type="success"
          @click="submitUpload"
          >上传到服务器</el-button
        >
        <div slot="tip" class="el-upload__tip">
          只能上传.xlsx文件
        </div>
      </el-upload>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogUpload = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import configDate from "../../../utils/config";
//import headtitle from "@/components/ControlPanel/headtitle";
export default {
  components: {
    //headtitle
  },
  data() {
    return {
      fileList: [],
      host: configDate.url,
      dialogUpload: false,
      UploadData: {
        creatorId: "",
      },
      thistownship: "",
      adddialog: false,
      disabled: false,
      page: 1,
      pages: 0,
      pageSize: 10,
      total: 1,
      currtow: parseInt(sessionStorage.getItem("currtow")) || 1,
      tableData: [],
      searchForm: {
        keyword: "",
      },
      ruleForm: {
        cellphone: "",
        name: "",
        password: "",
        township: "",
        username: "",
        status: 1,
      },
      multipleSelection: [],
      rules: {
        cellphone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          // 这个只能验证手机号
          //{ pattern:/^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/, message: "请输入合法手机号/电话号", trigger: "blur" }
          {
            pattern: /^1[345789]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        name: [
          { required: true, message: "请输入管理员名称", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20 个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            pattern: /^(?=.*\d)(?=.*[!,@,#,$,%,^,&,*,.,~])(?=.*[a-z])(?=.*[A-Z]).{6,15}/,
            message: "密码格式为：Aa#123；且不可以小于6位",
            trigger: "blur",
          },
        ],
        township: [
          { required: true, message: "请输入乡镇名称", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20 个字符",
            trigger: "blur",
          },
        ],
        username: [
          { required: true, message: "请输入用户账号", trigger: "blur" },
          {
            min: 5,
            max: 20,
            message: "长度在 5 到 20 个字符",
            trigger: "blur",
          },
          {
            pattern: /^[A-Za-z]+$/,
            message: "用户名只能为字母",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getlist();
  },
  methods: {
    request() {
      let newPage = this.$router.resolve({
        name: "EntryStoma",
      });
      window.open(newPage.href, "_blank");
    },
    exportTable() {
      this.$axios
        .get("/worthy/export/excel?", {
          responseType: "blob", // 设置响应数据类型
        })
        .then((response) => {
          if (response.status === 200) {
            let fileName = "乡贤";
            let url = window.URL.createObjectURL(new Blob([response.data]));
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", fileName + "数据.xlsx");
            document.body.appendChild(link);
            link.click();
          }
        });
    },
    //刷新
    requestRefresh() {
      this.requestPage(1);
      this.currtow = 1;
      sessionStorage.removeItem("currtow");
      this.searchForm = {
        keyword: "",
      };
    },
    //获取列表分页
    getlist() {
      const self = this;
      this.$axios
        .get("/worthy/manager/page", {
          params: {
            current: parseInt(sessionStorage.getItem("currtow")) || 1,
            size: 10,
          },
        })
        .then(function(res) {
          console.log(res);
          self.tableData = res.data.data.records;
          self.total = res.data.data.total;
        });
    },

    /**
     * 分页
     * @param num number
     */
    requestPage(num) {
      const self = this;
      this.currtow = num;
      this.$axios
        .get("/worthy/manager/page", {
          params: {
            current: num,
            size: 10,
          },
        })
        .then(function(res) {
          self.tableData = res.data.data.records;
          self.total = res.data.data.total;
        });
    },
    /**
     * 查看详情
     * @param index number
     * @param row object
     */
    handleEdit(index, row) {
      sessionStorage.setItem("currtow", this.currtow);
      this.$router.push({ path: "/townsmenwz/" + row.id });
    },
    /**
     * 筛选
     * @param rows object
     */
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleCurrentChange(val) {
      console.log(val.id);
      this.thistownship = val.township || "";
      this.UploadData.creatorId = val.id;
    },
    isUpload() {
      if (this.UploadData.creatorId != "" && this.thistownship != "") {
        this.dialogUpload = true;
      } else {
        this.$message({
          type: "info",
          message: "请选择乡镇",
        });
      }
    },
    //查询
    searchSubmit() {
      const self = this;
      this.$axios
        .get("/worthy/manager/page", {
          params: {
            current: 1,
            keyword: self.searchForm.keyword,
            size: 10,
          },
        })
        .then(function(res) {
          self.tableData = res.data.data.records;
          self.total = res.data.data.total;
          console.log(res.data.data.records);
        });
    },
    addmanage() {
      const self = this;
      this.adddialog = true;
    },
    addClose() {
      this.adddialog = false;
      this.ruleForm = {
        cellphone: "",
        name: "",
        password: "",
        township: "",
        username: "",
        status: 1,
      };
    },
    addrule(formName) {
      const self = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios
            .post("/worthy/manager", this.ruleForm)
            .then(function(res) {
              console.log(res);
              if (res.data.status === 200) {
                self.$root.success("添加成功");
                self.addClose();
                self.requestPage(1);
              } else {
                self.disabled = true;
                setTimeout(() => {
                  self.disabled = false;
                }, 2000);
                if (
                  res.data.data ===
                  "account [" +
                    self.ruleForm.username +
                    "] by [id] already exists."
                ) {
                  //account [admin3] by [id] already exists
                  self.ruleForm.username = "";
                  self.$message({
                    type: "info",
                    message: "用户名已存在，请重新输入",
                  });
                } else {
                  self.$message({
                    type: "warn",
                    message: res.data.data,
                  });
                }
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //批量导入乡贤
    submitUpload() {
      this.$refs.upload.submit();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
  },
};
</script>

<style lang="less" scoped>
#pagination {
  margin: 32px 0 16px 0;
}

button {
  color: #ffffff;
}

.text-color {
  color: #2a3746;
}
</style>
